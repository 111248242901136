import React from 'react';
import { graphql } from 'gatsby';
import IndexPage from './index';

export default props => <IndexPage {...props} onlyDetox />;

export const query = graphql`
  query VeganQuery {
    ...HomeData
  }
`;
